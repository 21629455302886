import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';
import { ReactComponent as StoreIcon } from '../../../svg/Store.svg';
import CartButton from '../../Cart/CartButton';
import MaxWidth from '../MaxWidth';
import MainNav from './MainNav';
import HeaderButtons from './HeaderButtons';
import MobileMenu from './MobileMenu';
import InfoBar from './InfoBar';
import TopNav from './TopNav.js';
import Logo from '../../ui/Logo';
import MenuIcon from '../../ui/MenuIcon';
import FullSearchField from './FullSearchField';
import { Notifications } from '../Notifications';
import { StoreContext } from '../../StoreHandler/StoreContext';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../svg/Arrow.svg';
import { useCookies } from 'react-cookie';

import { ReactComponent as PhoneIcon } from '../../../svg/Phone.svg';

const Container = styled('header')`
  position: relative;
  background-color: ${theme.colors.white};
  z-index: 11;

  ${theme.below.md} {
    height: 64px;
  }
  dialog {
    text-align: center;
    position: fixed;
    top: 20%;
    h1 {
      margin: 0;
      font-size: 1rem;
    }
    box-shadow: 5px 10px rgba(0, 0, 0, 0.2);
    width: 80vw;
    min-height: 200px;
    border-radius: 8px;
    border: none;
    select {
      font-weight: 100;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      padding: 0.5rem;
      font-size: 1rem;
      border-radius: 8px;
      width: 80%;
      margin: 1rem 0;
    }
    button.closeBtn {
      background: none;
      color: #000;
      font-size: 2rem;
      font-weight: 100;
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      line-height: 0.5;
    }
    button {
      padding: 0.5rem;
      font-weight: 100;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      background-color: ${theme.colors.darkRed};
      border-radius: 8px;
      color: white;
      border: none;
      width: 80%;
    }
  }
`;

const Overlay = styled('div')`
  display: ${(props) => (props.menuOverlay ? `block` : `none`)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

const MenuWrapper = styled(MaxWidth)`
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const MobileHeader = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px 0px rgba(150, 150, 150, 0.1);
`;

const Nav = styled('nav')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: inherit;
  background-color: white;
  z-index: 2;
`;

const linkStyle = `
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: ${theme.colors.white};
  padding: 5px;
  border-radius: 4px;
  text-decoration: none;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    margin-top: 5px;
    font-size: 12px;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    color: #333333;
  }
`;

const StyledLink = styled(Link)`
  ${linkStyle}
`;

const StyledButton = styled('button')`
  ${linkStyle}
`;

const StoreNavigatorWrapper = styled('div')`
  z-index: 99;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;
  background-color: ${theme.colors.tablegrey};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 5px;
  span {
    display: inline-flex;
    align-items: center;
  }
  svg {
    width: 1rem;
    margin-right: 0.5rem;
  }
  span.show-more {
    margin-left: 0.5rem;
    color: ${theme.colors.grey};
  }
  .quickInfo {
    font-size: 12px;
    display: flex;
    height: 2rem;
    & > span {
      padding: 0.5rem 1rem;
    }
    ${theme.below.lg} {
      display: none;
    }
  }
  .infoBox {
    font-size: 14px;
    overflow: hidden;
    background-color: ${theme.colors.tablegrey};
    position: absolute;
    left: -0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    top: 100%;

    min-width: 500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr max-content;
    gap: 0px 0px;
    grid-template-areas:
      'info info info time time'
      'info info info time time'
      'contact contact contact contact contact';
    border-radius: 0 0 5px 5px;
    h3 {
      margin: 0;
    }
    th {
      text-align: left;
      vertical-align: top;
      padding-right: 1rem;
    }
    .openHours {
      grid-area: time;
      white-space: pre;
      padding: 1rem;
    }
    .contact {
      grid-area: contact;
      background-color: ${theme.colors.white};
      padding: 1rem;
    }
    .info {
      grid-area: info;
      padding: 1rem;
      white-space: pre-line;
    }
    ${theme.below.md} {
      position: relative;
      top: 0;
      left: 0;
      min-width: 100%;
      grid-template-areas:
        'info info info info info'
        'time time time time time'
        'contact contact contact contact contact';
      box-shadow: none;
    }
  }
  .storeListWrapper {
    display: flex;
  }
  ${theme.below.md} {
    .storeListWrapper {
      width: 100%;
      flex-direction: column;
    }
  }
  .storelist {
    position: relative;
    width: max-content;
    display: inline-flex;
    align-items: center;
    ${theme.below.md} {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .clearSelection {
      padding: 3px 6px;
      font-size: 1.4rem;
      line-height: 1rem;
      cursor: pointer;
      span {
        display: none;
        font-size: 1rem;
        text-align: center;
        padding: 3px;
        ${theme.below.md} {
          display: block;
        }
      }
    }

    button,
    .selectedStore {
      text-transform: uppercase;
      font-weight: 100;
      height: 2rem;
      background-color: ${theme.colors.darkRed};
      color: white;
      border: none;
      padding: 0.5rem;
      min-width: 10rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: ${theme.colors.hoverRed};
      }
      span {
        font-size: 12px;
      }
      svg {
        margin-right: 0rem;
        path {
          stroke: white;
        }
      }
      ${theme.below.md} {
        width: 100%;
        padding: 1rem 0.75rem;
        height: auto;
        justify-content: space-between;
        border-radius: 0.5rem;
      }
    }
    .selectedStore {
      display: flex;
      svg {
        path {
          stroke: none;
          fill: white;
        }
        path.door {
          fill: ${theme.colors.darkRed};
        }
      }
      &:hover {
        path.door {
          fill: ${theme.colors.hoverRed};
        }
      }
    }
    ul {
      width: 100%;
      min-width: 10rem;
      padding: 0.5rem;
      margin: 0;
      background-color: ${theme.colors.tablegrey};
      position: absolute;
      left: 0rem;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      top: 100%;
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      border-radius: 0 0 5px 5px;
      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        background: ${theme.colors.lightgrey};
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.darkRed};
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: ${theme.colors.hoverRed};
      }
      li {
        cursor: pointer;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: 100;
        padding: 0.5rem;
        width: 100%;
        white-space: nowrap;
        border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
      }
      ${theme.below.lg} {
        max-height: 200vh;
        position: static;
        box-shadow: none;
      }
    }
  }
`;

const StoreMiniMenuWrapper = styled('nav')`
  margin: 0 1rem;
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    li {
      padding: 0 0.5rem;
      line-height: 32px;
      a {
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        color: #000;
      }
    }
  }
`;

const StoreMiniMenu = ({ selectedStore }) => {
  return (
    <StoreMiniMenuWrapper>
      <ul>
        {selectedStore?.menuPages?.map((page) => (
          <>
            <li>
              <Link to={`/${selectedStore.store_slug}/${page.slug}`}>
                {page.title}
              </Link>
            </li>
          </>
        ))}
      </ul>
    </StoreMiniMenuWrapper>
  );
};

const StoreSelectDialog = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['closedStoreDialog']);

  const { storeList, selectedStore, updateStore, storeSlugs } =
    useContext(StoreContext);
  const [selected, setSelected] = useState(null);

  const setStore = () => {
    updateStore(storeList.find((store) => store.id === parseInt(selected)));
  };

  const closeDialog = () => {
    setCookie('closedStoreDialog', true, { path: '/' });
  };

  return (
    <dialog
      className="hideInPrint"
      open={!selectedStore && !cookies['closedStoreDialog']}
    >
      <h1>Välkommen till Möbelmästarna</h1>
      Välj din butik för att se aktuella kampanjer, erbjudanden och lokalt
      sortiment.
      <select
        onChange={(e) => {
          setSelected(e.target.value);
        }}
      >
        <option value={null}>--- Gör ditt val ---</option>
        {storeList?.map((store) => (
          <option value={store?.id} key={store?.id}>
            {store?.name}
          </option>
        ))}
      </select>
      <button disabled={!selected} onClick={setStore}>
        Välj butik
      </button>
      <button onClick={closeDialog} className="closeBtn">
        ×
      </button>
    </dialog>
  );
};

export const StoreNavigator = ({ ...props }) => {
  const { storeList, selectedStore, updateStore, storeSlugs } =
    useContext(StoreContext);

  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();

  const showMoreHandler = () => {
    setOpen(false);
    setShowMore(!showMore);
  };

  const setOpenHandler = () => {
    setShowMore(false);
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedStore && storeSlugs) {
      if (
        storeSlugs?.includes(history?.location?.pathname?.replace('/', '')) ||
        history?.location?.pathname === '/' ||
        history?.location?.pathname === ''
      ) {
        history?.push(`/${selectedStore?.store_slug}`);
      }
    }
  }, [selectedStore, storeSlugs, history]);

  //   console.log(storeList);
  return (
    <StoreNavigatorWrapper>
      <div className="storeListWrapper">
        <div className="storelist">
          {selectedStore ? (
            <>
              <div className="selectedStore">
                <span onClick={setOpenHandler}>
                  <StoreIcon /> {selectedStore?.name}{' '}
                </span>
                {selectedStore?.storeInfo && (
                  <span className="show-more" onClick={showMoreHandler}>
                    {showMore ? '× Stäng' : 'Läs mer'}
                  </span>
                )}
              </div>
              <span
                onClick={() => updateStore(null)}
                className="clearSelection"
              >
                &times; <span>Rensa butiksval</span>
              </span>
            </>
          ) : (
            <>
              <button onClick={() => setOpen(!open)}>
                Välj din butik <Arrow />
              </button>
            </>
          )}
          {showMore && (
            <div className="infoBox">
              <div className="info">
                <strong>{selectedStore?.name}</strong>
                <br />
                {selectedStore?.storeInfo?.description}
              </div>
              <div className="contact">
                <table>
                  {selectedStore?.storeInfo?.phone && (
                    <tr>
                      <th>Tel:</th>
                      <td>{selectedStore?.storeInfo?.phone}</td>
                    </tr>
                  )}
                  {selectedStore?.storeInfo?.email && (
                    <tr>
                      <th>E-post:</th>
                      <td>{selectedStore?.storeInfo?.email}</td>
                    </tr>
                  )}
                  {selectedStore?.storeInfo?.address && (
                    <tr>
                      <th>Adress:</th>
                      <td>
                        {selectedStore?.storeInfo?.address}
                        <br />
                        {selectedStore?.storeInfo?.zip}{' '}
                        {selectedStore?.storeInfo?.city}
                      </td>
                    </tr>
                  )}
                </table>
              </div>
              {selectedStore?.storeInfo &&
                'open-hours' in selectedStore.storeInfo && (
                  <div className="openHours">
                    <strong>Öppettider</strong>
                    <br />
                    {selectedStore.storeInfo['open-hours']}
                  </div>
                )}
            </div>
          )}
          {open && (
            <ul>
              {storeList?.map((store) => (
                <li
                  key={store?.id}
                  onClick={() => {
                    updateStore(store);
                    setOpen(false);
                  }}
                >
                  {store?.name}
                </li>
              ))}
            </ul>
          )}
        </div>

        {selectedStore && <StoreMiniMenu selectedStore={selectedStore} />}
      </div>

      <div className="quickInfo">
        {props?.pageLinks && <InfoBar pageLinks={props?.pageLinks} />}
        {selectedStore && (
          <span>
            <PhoneIcon />
            {selectedStore?.storeInfo?.phone}
          </span>
        )}
      </div>
    </StoreNavigatorWrapper>
  );
};

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${year} - ${month} - ${date}`;
}

function Header(props) {
  const { storeList } = useContext(StoreContext);
  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [menuOverlay, setMenuOverlay] = useState(false);
  const [currentDate, setCurrentDate] = useState(getDate());

  const toggleMobileMenu = () => setMobileMenuOpen((open) => !open);

  return (
    <>
      {/* For print */}
      <p className="showInPrint dateInPrint">{currentDate}</p>

      <div className="hideInDisplay printLogo">
        <Logo size={133} />
      </div>

      <Overlay menuOverlay={menuOverlay} />
      <Container className="hideInPrint">
        <Below breakpoint="md">
          {(matches) =>
            matches ? (
              <>
                <Nav>
                  {searchOpen ? (
                    <FullSearchField onCancel={() => setSearchOpen(false)} />
                  ) : (
                    <>
                      <MobileHeader>
                        <TopNav left>
                          <StyledButton onClick={toggleMobileMenu}>
                            <MenuIcon showMenu={mobileMenuOpen} />
                            <span>{t('Menu')}</span>
                          </StyledButton>
                          <StyledButton
                            onClick={() => setSearchOpen(true)}
                            id="hr-search"
                          >
                            <SearchIcon />
                            <span>{t('Search')}</span>
                          </StyledButton>
                        </TopNav>
                        <Logo size={67} />
                        <TopNav right>
                          <StyledLink
                            className="mobile-store-button"
                            to="/butiker"
                          >
                            <StoreIcon />
                            <span>{t('Stores')}</span>
                          </StyledLink>
                          <CartButton />
                        </TopNav>
                      </MobileHeader>
                      <StoreSelectDialog />
                    </>
                  )}
                </Nav>
                {mobileMenuOpen && (
                  <MobileMenu
                    isOpen={mobileMenuOpen}
                    closeMenu={toggleMobileMenu}
                    data={props?.result?.data}
                    pageLinks={props?.pageLinks}
                  />
                )}
              </>
            ) : (
              <>
                <StoreNavigator pageLinks={props?.pageLinks} />

                <MenuWrapper>
                  <Logo size={133} />
                  <FullSearchField />
                  <HeaderButtons />
                </MenuWrapper>
                <MainNav
                  setSearchOpen={() => setSearchOpen(true)}
                  searchIsOpen={searchOpen}
                  categories={props?.result}
                  setMenuOverlay={setMenuOverlay}
                />
              </>
            )
          }
        </Below>
        <Notifications />
      </Container>
    </>
  );
}

const HeaderComp = ({ pageLinks }) => (
  <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
    {(result) => <Header result={result} pageLinks={pageLinks} />}
  </Query>
);

export default HeaderComp;
