import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/';
import ProductLink from '@jetshop/ui/ProductLink';
import CustomBadges from '../ui/CustomBadges';
import { Price } from '@jetshop/ui/Price';
import ColorBox from '../ui/ColorBox';

import { ProductDetailsWrapper } from '../ProductPage/styledComponents';
import { PriceComponent } from '../ProductPage/PriceComponent';
// import { LipscoreProductSmallRating } from '../ProductPage/Lipscore/LipscoreProductSmallRating';

export const ProductWrapper = styled('div')`
  width: calc(100% / 4);

  ${theme.below.lg} {
    width: calc(100% / 3);
  }
  ${theme.below.md} {
    width: calc(100% / 3);
  }
  ${theme.below.sm} {
    width: 50%;
  }

  margin-left: -1px;
`;

const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: white;
  padding: 10px 0;
  position: relative;
  height: 100%;
  margin: 0px 6px;

  [data-flight-image-container] {
    background: #f5f5f5;
  }
  [data-flight-image] {
    img {
      mix-blend-mode: multiply;
      transform: translate3d(0, 0, 0);
    }
  }

  .box {
    outline: 2px solid #f5f5f5;
    outline-offset: -3px;
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: fit-content;
  position: relative;
`;

const Name = styled('p')`
  color: #000;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  padding: 0;
  margin: 0;
`;

const ProductDetails = styled(ProductDetailsWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .more-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    justify-content: flex-end;
    [data-flight-price] {
      flex-direction: column;
      .new-price,
      .old-price,
      .price {
        margin: 0;
      }
    }
  }
  .subname {
    color: gray;
    font-size: 10px;
    font-style: italic;
    text-align: left;
  }
  &.mastarpris {
    .new-price {
      line-height: 16px;
      color: #ef843c;
      font-weight: 700;
    }
    .old-price {
      opacity: 0.3;
      line-height: 18px;
      text-decoration: line-through;
    }
  }

  padding: 10px;
  align-items: flex-start;
  height: auto;
  justify-content: space-between;

  ${Price.Wrapper} {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    color: ${theme.colors.black};
  }

  ${Price.Old} {
    opacity: 0.3;
  }

  ${Price.New} {
    margin-right: 0.5rem;
  }

  ${theme.below.lg} {
    flex-direction: column;

    .more-info {
      justify-content: flex-start;

      [data-flight-price] {
        flex-direction: row;
        .new-price {
          margin-right: 5px;
        }
      }
    }
  }
`;

const ColorBoxesWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  min-height: 15px;
  margin: 10px;
`;

const VariantsRatingsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background: #f5f5f5;
`;

export const ProductCard = ({
  product,
  categoryPath,
  loadImageEagerly = false,
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  if (!product) return null;

  const handleMouseOver = () => {
    if (product.images.length > 1) {
      if (imageIndex === 1) {
        setImageIndex(0);
      } else {
        setImageIndex(1);
      }
    }
  };

  const mastarPris = product?.customFields?.find(
    ({ key }) => key === 'Mastarpris'
  )?.boolValue;

  const colorVariants = product?.variants?.options?.find((option) =>
    option?.name?.toLowerCase()?.includes('färg')
  )?.values;

  const moddedProduct = {
    ...product,
    primaryRoute: {
      ...product?.primaryRoute,
      path: product?.primaryRoute?.path?.replace(
        'https://www.mobelmastarna.se',
        ''
      ),
    },
  };

  return (
    <ProductBlockLink
      className="product-card"
      product={moddedProduct}
      categoryPath={categoryPath}
      onMouseEnter={() => handleMouseOver()}
      onMouseLeave={() => handleMouseOver()}
    >
      <ImageWrapper>
        <Image
          sizes={[1 / 4, 1 / 3, 1 / 2, 1 / 2]}
          alt={product?.name}
          src={
            product?.images?.length > 1
              ? product?.images[imageIndex]?.url
              : product?.images[0]?.url
          }
        />
        {product?.badges && <CustomBadges badges={product.badges} />}
      </ImageWrapper>
      <VariantsRatingsWrapper>
        <ColorBoxesWrapper>
          {colorVariants?.map((color, index) => (
            <ColorBox group={color} key={color + index} />
          ))}
        </ColorBoxesWrapper>
        {/* {product && <LipscoreProductSmallRating product={product} onCard />} */}
      </VariantsRatingsWrapper>
      <ProductDetails className={mastarPris ? 'mastarpris' : null}>
        <Name>{product?.name}</Name>
        <div className="more-info">
          <PriceComponent product={product} onCard={true} />
        </div>
      </ProductDetails>
    </ProductBlockLink>
  );
};

export const SearchProductCard = (props) => (
  <ProductWrapper>
    <ProductCard {...props} />
  </ProductWrapper>
);
